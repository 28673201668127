<template>
  <div>
    
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading">
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Starter page component
 */
export default {

  data() {
    return {
      loading: true,
      itemlist: [],
    };
  },
  mounted() {
    
  },
  methods: {
    getitemlist(){
        
    }
  },
};
</script>